p {
	font-size: 18px !important;
}

.blackBG {
	position: inherit !important;
	z-index: 2000;
	background-color: rgb(0, 0, 0);
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(64, 64, 64, 1) 91%
	);
	height: 100px;
	width: 100%;
	border-radius: 3px;
	text-align: center;
	display: grid;
	align-content: center;
	border: 10px solid #fff;
	margin-top: 10px;
}

.contactTitle {
	color: #39a11c;
	font-size: 2.6rem;
}

.firstServiceBox {
	text-align: center;
	padding: 10px;
	height: 100%;
	display: grid;
	align-content: center;
}

.nojudge {
	display: block;
	margin: 0 auto;
	width: 300px;
}
.nojudge img {
	width: 250px;
}

.serviceBox {
	padding: 10% 10% 0 10%;
}

.serviceBoxGrid {
}

.serviceBox1 {
	border: 4px solid black;
	padding: 5%;
	background-color: #ffffff;
}

.boxTitle {
	background-color: #fae296;
	border: 3px solid black;
	font-size: 1.4rem;
	text-align: center;
	-webkit-box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 1);
	-moz-box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 1);
	box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 1);
}

.boxTitle h3 {
	padding: 10px;
	margin: 0;
}

.topGridImg {
	width: 100%;
}

.topImgGrid {
	display: grid;
	align-content: center;
	padding: 5px;
}

.serviceBTN {
	background-color: #39a11c !important;
	color: #fff !important;
	font-size: 1.2rem !important;
	margin-left: 40px !important;
	margin-top: -30px !important;
	padding: 10px 30px !important;
	border: 3px solid black !important;
}

.serviceBTN:hover {
	background-color: #45b127 !important;
	border: none !important;
}

.emailBTN {
	display: block !important;
	margin: 0 auto !important;
}
.textBTN {
	display: none !important;
}

.textQuote {
	width: 100%;
	text-align: center;
	padding: 30px;
}

.secondRowDiv {
	width: 100%;
}
.secondCenter {
	display: block;
	margin: 0 auto;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
	.emailBTN {
		display: none !important;
	}
	.textBTN {
		display: block !important;
		margin: 0 auto !important;
	}
	.serviceBox1 {
		min-height: auto !important;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 472px) {
	.topUL {
		margin-bottom: 0px;
	}
	.bottomUL {
		margin-top: -25px;
	}
}

.serviceList ul {
	display: inline-block;
}

.serviceList {
	font-size: 1.3rem;
}

.serviceBox1 {
	min-height: 300px;
}

.ulServe {
	margin-top: 0px;
}

.payment {
	width: 70px;
	padding: 15px;
}
.payment img {
	width: 100%;
}

.paymentMethods {
	display: flex;
	align-content: center;
	justify-content: center;
}

.paymentMain {
	text-align: center;
	margin-top: -34px !important;
}
.paymentMain h3 {
	margin-bottom: 0;
}

.serviceBottom {
	background-color: rgb(82, 0, 138);
	background: linear-gradient(
		90deg,
		rgba(82, 0, 138, 1) 0%,
		rgb(119, 13, 155) 91%
	);
	color: #ffffff;
	display: flex;
	text-align: center;
	padding: 30px;
	margin-top: 70px;
}

.stepsDiv {
	padding: 0px 80px !important;
}

.stepNumber {
	width: 40px;
	height: 40px;
	background-color: #45b127;
	box-shadow: 4px 4px 0px 0px rgba(255, 255, 255, 1);
	-webkit-box-shadow: 4px 4px 0px 0px rgba(255, 255, 255, 1);
	-moz-box-shadow: 4px 4px 0px 0px rgba(255, 255, 255, 1);
	display: grid;
	align-content: center;
	margin: 0 auto;
}

.bottomServiceH3 {
	font-size: 2.4rem !important;
	margin-top: 0px !important;
}

.stepBlurb {
	text-align: left;
	font-size: 1.9rem;
	width: 300px;
	display: block;
	margin: 0 auto;
}

.stepBlurb p {
	font-size: 1.4rem !important;
}

.stepBlurb a {
	font-size: 1.4rem !important;
	color: #777;
}
.stepBlurb a:hover {
	font-size: 1.4rem !important;
	color: rgb(219, 219, 219);
}
