.blackBG {
	position: inherit;
	z-index: 2000;
	background-color: rgb(0, 0, 0);
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(64, 64, 64, 1) 91%
	);
	height: 100px;
	width: 100%;
	border-radius: 3px;
	text-align: center;
	display: grid;
	align-content: center;
	border: 10px solid #fff;
}

.contactBTN {
	background-color: #39a11c !important;
	margin: 10px !important;
}
.contactBTN:hover {
	background-color: #7fcc6a !important;
	margin: 10px !important;
}

.MuiButton-label {
	font-size: 1.2rem;
}

.infoText {
	margin-left: 10px;
	display: grid;
	align-content: center;
}

.infoText p,
h6 {
	margin: 0;
}

.infoText h6 {
	font-size: 1.6rem;
}
.infoText p {
	font-size: 1.1rem;
}

@media only screen and (max-width: 768px) {
	.mainGrid {
		height: 100% !important;
		padding: 30px 10px;
	}
	.missBlurb {
		height: auto !important;
	}

	.makeStyles-missBlurb-245 {
		height: auto !important;
	}

	.imgDiv img {
		width: 200px;
		margin-top: -80px;
	}
	.makeStyles-bottomH3-250 {
		margin-top: 0px;
	}
	.borders {
		width: 85%;
		display: block;
		margin: 0 auto;
	}
	.bottomH3 {
		font-size: 1.9rem;
	}
}

.bottomH3 {
	font-size: 2.4rem;
}

.mainGrid {
	display: flex;

	padding: 10px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}
