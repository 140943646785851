body {
	margin: 0;
	padding: 0;
	font-family: 'Bangers', cursive;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h3 {
	letter-spacing: 2px;
}
