header#navBG {
	background-color: rgb(0, 0, 0);
	font-family: 'Bebas Neue', cursive;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.MuiSvgIcon-root {
	color: #39a11c;
}

.makeStyles-content-9 {
	padding-bottom: 0px !important;
	padding-top: 0px !important;
}

.list-unstyled {
	width: 100%;
	list-style-type: none;
	padding-left: 0px;
	margin-top: 20px;
}

.list-unstyled li {
	text-decoration: none !important;
	color: #39a11c;
	padding: 16px;
	text-align: center;
}
.list-unstyled a li:hover {
	background-color: #39a11c;
	color: #000;
}
.list-unstyled a li {
	text-decoration: none !important;
	width: 100%;
	color: #39a11c;
	font-size: 1.1rem;
}

.main-nav-active li {
	background-color: rgba(109, 255, 80, 0.445);
	color: #000000 !important;
}

.list-unstyled a {
	text-decoration: none !important;
}

.numberClick {
	text-align: center;
}

.numberClick a {
	color: #fefb28;
	font-size: 1.4rem;
}

/* 
Sudden North Logo Area */

.SuddenNorth {
	width: 100%;
	height: 200px;

	bottom: 0;
	display: grid;
	justify-content: center;
}

.SNlogoDiv {
	width: 100%;
	color: #000000;
	text-align: center;
	padding: 50px 15px;
}

.SNlogoDiv img {
	width: 70%;
}

.SNlogoDiv p {
	color: #9640b9;
	font-size: 12px !important;
}
