#headerImg {
	background-image: url('../../assets/images/headerIMG.jpg'); /* The image used */
	background-color: #cccccc; /* Used if the image is unavailable */
	height: 500px !important; /* You must set a specified height */
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
	text-align: left;
}

@media only screen and (max-width: 802px) and (min-width: 768px) {
	#greenGuyIMG {
		left: 50%;
		bottom: 25px;
	}
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
	.divBody {
		padding: 50px 0px 0px 0px;
	}

	#headerH3 {
		font-size: 2.5rem !important;
		margin-bottom: 10px;
	}
	#headerH6 {
		font-size: 1.5rem !important;
		margin-top: 0px;
		margin-bottom: 100px;
	}
	#greenGuy {
		margin-top: 0px;
	}
	#emailButton {
		display: none;
	}
	#textButton {
		display: block;
	}

	#gridMargin {
		margin-top: 100px;
	}
	#greenGuyIMG {
		width: 300px;
		margin-bottom: -240px;
	}
}

@media only screen and (min-width: 864px) {
	#greenGuyIMG {
		left: 50%;
		bottom: -25px;
	}
}

@media only screen and (min-width: 769px) {
	#emailButton {
		display: block;
		position: absolute;
		margin-top: 60px;
	}
	#textButton {
		display: none;
	}
}

@media screen and (max-width: 862px) and (min-width: 805px) {
	#greenGuyIMG {
		left: 50%;
		bottom: -55px !important;
	}
}

a {
	text-decoration: none;
}

@media screen and (min-width: 558px) {
	#greenGuyIMG {
		width: 400px;
	}
}

@media screen and (max-width: 558px) and (min-width: 416px) {
	#greenGuyIMG {
		margin-bottom: -200px;
	}
}

@media screen and (max-width: 558px) and (min-width: 416px) {
	#greenGuyIMG {
		margin-bottom: -200px;
	}
}
@media screen and (max-width: 415px) {
	#greenGuyIMG {
		margin-bottom: -180px !important;
	}
}
