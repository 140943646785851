.homeBody {
	font-family: 'Bangers', cursive !important;
	background-image: url('../assets/images/lights.jpg'); /* The image used */
	background-color: #cccccc; /* Used if the image is unavailable */
	min-height: 100vh; /* You must set a specified height */
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
}

.divBody {
	font-family: 'Bangers', cursive !important;
}
.divBody2 {
	background-image: url('../assets/images/lights2.jpg'); /* The image used */
	background-color: #cccccc; /* Used if the image is unavailable */
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
	min-height: 100vh;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
	#contBG {
		padding-top: 10px !important;
	}
	.homeBody {
		margin-top: 20px;
	}
	.reviewBG {
		height: 100% !important;
	}
}

.MuiContainer-root {
	padding-left: 10px !important;
	padding-right: 10px !important;
}

.mainTitleAbout {
	padding-left: -10px;
}

.bioPadding {
	padding: 10px;
	text-align: center;
}

.bottomGrids {
	padding: 10px;
}

.borders {
	background-color: #ffffff;
	border: 4px solid black;
}

.imgDiv img {
	width: 300px;
	margin-top: -100px;
}

/* ---------------------- This is for the review page -------------------------- */

.reviewBG {
	background-image: url('../assets/images/lights2.jpg'); /* The image used */
	background-color: #cccccc; /* Used if the image is unavailable */
	min-height: 100vh;
	background-position: center center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
}
