body {
	font-family: 'Bangers', cursive !important;
}

.blackBG {
	position: inherit;
	z-index: 2000;
	background-color: rgb(0, 0, 0);
	background: linear-gradient(
		90deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(64, 64, 64, 1) 91%
	);
	height: 100px;
	width: 100%;
	border-radius: 3px;
	text-align: center;
	display: grid;
	align-content: center;
	border: 10px solid #fff;
}

.wordIMGDiv img {
	width: 300px;
	display: block;
	margin: 40px auto 60px auto;
}

.slideBG {
	background-color: #ffffff;
}

.carouselBG {
	padding: 40px;
}

.reviewGrid {
	padding: 10px;
}

.reviewsPaper {
	background-color: #ffffff;
	border: 4px solid black;
	padding: 30px;
}

.reviewBlurb {
	font-size: 1.2rem;
}

.reviewerName {
	background-color: #fae296;
	border: 3px solid black;
	font-size: 0.9rem;
	text-align: center;
	-webkit-box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 1);
	-moz-box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 1);
	box-shadow: 7px 7px 0px 0px rgba(0, 0, 0, 1);
	width: 180px;
	letter-spacing: 2px;
}

.platformName {
	font-size: 1.4rem;
}

.reviewPlateformIcon {
	width: 40px;
}
.reviewPlateformFiveStar {
	width: 100px;
	padding-bottom: 10px;
	margin-left: 10px;
}

.reviewFooter {
	background-color: rgb(82, 0, 138);
	background: linear-gradient(
		90deg,
		rgba(82, 0, 138, 1) 0%,
		rgb(119, 13, 155) 91%
	);
	text-align: center;
	padding: 10px 10px;
	width: 100%;
	color: #fff;
	border: 10px solid #fff;
	/* position: absolute;
	bottom: 0; */
}

.reviewFooter h3 {
	font-size: 1.8rem;
	letter-spacing: 2px;
}

.shareDiv {
	padding: 30px;
}

.share {
	text-align: center;
	border: 4px solid #000000;
	background-color: green;
	width: 300px;
	display: block;
	margin: 0 auto;
	color: #ffffff;
	font-size: 1.5rem;
	margin-top: 80px;
	letter-spacing: 2px;
}

.socialPlatformIcons {
	background-color: #ffffff;
	border: 4px solid #000000;
	width: 70px;
	padding: 10px;
	margin: 10px;
}

.socialPlatformIcons img {
	width: 100%;
	display: block;
	margin: 0 auto;
}
.rightReviewSide ul {
	display: block;
	margin: 0 auto;
	padding-left: 0 !important;
	text-align: center;
}

.rightReviewSide ul li {
	display: inline-block;
}

@media only screen and (max-width: 768px) {
	.share {
		margin-top: 0px;
	}
}
/* 
@media only screen and (max-height: 1100px) {
	.reviewFooter {
		position: static;
	}
} */

.reviewFooter a {
	font-size: 1.8rem;
	letter-spacing: 2px;
	color: #ffffff;
	margin-left: 15px;
}
.reviewFooter a:hover {
	color: #2ba367;
}
